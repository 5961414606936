import { Box, Button, CustomTyphography, Flexbox, IconButton } from 'components'
import { FC } from 'react'
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss';
import UserLogo from 'common/UserLogo';
import { CloseIcon, Logo, ThumbDownOffAltIcon, ThumbUpOffAltIcon } from 'components/icons';
import { Message } from 'utils/types';
import Markdown from 'components/Markdown';
import SidePanelDrawer from 'components/SidePanelDrawer';
import { useSelector } from 'react-redux';
import { referencesDataLoadingSelector, referencesDataSelector } from 'store/knowledgeBase-slice';
import { isMarkdown } from 'utils/string';
import KBSourcesSidebar from './KBSourcesSidebar';

const classes = classNames.bind(styles);

interface AMAMessageProps {
    message: Message
    showFeedbackConversation: boolean
    feedbackStatus: 'initial' | 'pending' | 'success' | null
    handlePRDPreview: () => void
    handleRefine: () => void
    handleConfirmRefinedPRD: () => void
    handleSendFeedback: (feedbackType: 'positive' | 'negative') => void,
    closeFeedbackConversation: () => void
    prdTitle?: string
    onGetKBSource: (kbId: number, kbItemIds: number[]) => Promise<void>
}

const AMAMessage: FC<AMAMessageProps> = ({
    message,
    showFeedbackConversation,
    feedbackStatus,
    handlePRDPreview,
    handleRefine,
    handleConfirmRefinedPRD,
    handleSendFeedback,
    closeFeedbackConversation,
    prdTitle,
    onGetKBSource
}) => {
    const referencesData = useSelector(referencesDataSelector)
    const referencesDataLoading = useSelector(referencesDataLoadingSelector)

    return (
        <Flexbox vertical fullWidth>
            <Flexbox
                className={classes('messageContainer', { fromAI: message.from === 'assistant' })}
            >
                <Flexbox className={classes('logoContainer')}>
                    {message.from === 'user' ? <UserLogo /> : <Logo />}
                </Flexbox>
                {message.from === 'user' ? (
                    <Flexbox className={classes('message')}>{message.text}</Flexbox>
                ) : (
                    <Flexbox
                        fullWidth
                        vertical
                    >
                        {message.text.type === 'text' ? (
                            <Markdown text={message.text.content?.replaceAll('```html', '')?.replaceAll('```', '')} />
                        ) : message.text.type === 'prd_preview' ? (
                            <Flexbox vertical className={classes('gap-6')}>
                                <Flexbox vertical className={classes('gap-2')}>
                                    <Flexbox>Product Requirement Documents is ready!</Flexbox>
                                    {!!prdTitle && (isMarkdown(prdTitle) ? <Markdown text={prdTitle?.replaceAll('```html', '')?.replaceAll('```', '')} /> : <Flexbox className={classes('prdTitle')}>{prdTitle}</Flexbox>)}
                                </Flexbox>
                                <Button
                                    sx={{
                                        width: '175px',
                                    }}
                                    variant='contained'
                                    onClick={handlePRDPreview}
                                >
                                    Click to review
                                </Button>
                            </Flexbox>
                        ) : message.text.type === 'refine' ? (
                            <Flexbox vertical fullWidth className={classes('gap-2')}>
                                <Markdown text={message.text.content?.replaceAll('```html', '')?.replaceAll('```', '')} />
                                <Flexbox align className={classes('gap-2')}>
                                    <Button
                                        variant='contained'
                                        onClick={handleConfirmRefinedPRD}
                                    >
                                        Looks good
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        onClick={handleRefine}
                                    >
                                        Lets refine further
                                    </Button>
                                </Flexbox>
                            </Flexbox>
                        ) : <Flexbox></Flexbox>
                        }
                        {!!message?.references?.length && (
                            <Flexbox align className={classes('gap-2', 'sources-container')}>
                                <CustomTyphography className={classes('font-500', 'sources-title')}>Sources:</CustomTyphography>
                                <Flexbox wrap align className={classes('gap-1')}>
                                    {message.references.map(ref => (
                                        <SidePanelDrawer
                                            key={ref.knowledge_id}
                                            containerClassName={classes('kbSidepanel')}
                                            actionElement={
                                                (props: any) => (
                                                    <Flexbox align {...props}>
                                                        <Box
                                                            className={classes('sources-kbSidepanel')}
                                                            onClick={() => onGetKBSource(ref.knowledge_id, ref.items.map(el => el.item_id))}
                                                        >
                                                            {ref.title}
                                                        </Box>
                                                    </Flexbox>
                                                )
                                            }
                                        >
                                            <KBSourcesSidebar
                                                title={'Sources'}
                                                complexFields={referencesData.map(ref => ({ ...ref.data, createdDate: ref.createdDate, lastModifiedDate: ref.lastModifiedDate }))}
                                                loading={referencesDataLoading}
                                            />
                                        </SidePanelDrawer>
                                    ))}
                                </Flexbox>
                            </Flexbox>
                        )}
                    </Flexbox>
                )}
            </Flexbox>
            {showFeedbackConversation && (
                <Flexbox justify align className={classes('userFeedbackContainer', 'gap-2')}>
                    {feedbackStatus === 'success' ? <CustomTyphography>Thank you for your feedback!</CustomTyphography> :
                        <>
                            <CustomTyphography>Is this conversation helpful so far?</CustomTyphography>
                            <Flexbox align fullHeight className={classes('rightBorder', 'gap-1', 'pr-2')}>
                                <IconButton onClick={() => handleSendFeedback('positive')}>
                                    <ThumbUpOffAltIcon />
                                </IconButton>
                                <IconButton onClick={() => handleSendFeedback('negative')}>
                                    <ThumbDownOffAltIcon />
                                </IconButton>
                            </Flexbox>
                            <IconButton onClick={closeFeedbackConversation}>
                                <CloseIcon />
                            </IconButton>
                        </>
                    }
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default AMAMessage