import { CommonButton, CustomTyphography, Dialog, Flexbox, Loader } from 'components'
import { ContentCopyIcon, Stars } from 'components/icons'
import PRDPreviewItem from './PRDPreviewItem'
import { FC, useState } from 'react'
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss';
import { PRDMainType } from 'utils/types';

const classes = classNames.bind(styles);

interface PRDPreviewDialogProps {
    open: boolean,
    onClose: () => void,
    loading: boolean,
    content?: PRDMainType,
    setAiRefineDialog: (arg: {
        open: boolean,
        field: string
    }) => void
}

const returnMainContent = (content: PRDMainType) => {
    if (content?.title) {
        const { title, ...restContent } = content;
        return restContent
    } else {
        return content
    }
}

const PRDPreviewDialog: FC<PRDPreviewDialogProps> = ({ open, onClose, loading, content, setAiRefineDialog }) => {
    const [isCopied, setIsCopied] = useState(false)

    const openAiRefineDialog = (key: string) => {
        setAiRefineDialog({
            open: true,
            field: key
        })
    }

    const handleCopyPRD = () => {
        const contentElement = document.querySelector('#copy_content');

        if (!contentElement) {
            console.error('Content element not found');
            return;
        }

        const elementsToExclude = contentElement.querySelectorAll<HTMLElement>('[data-copy="false"]');
        elementsToExclude.forEach(el => el.style.display = 'none');

        const range = document.createRange();
        range.selectNodeContents(contentElement);
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
            try {
                document.execCommand('copy');
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 1000)
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }

            selection.removeAllRanges();
        }

        elementsToExclude.forEach(el => el.style.display = '');
    };


    return (
        <Dialog
            id='copy_content'
            onClose={onClose}
            open={open}
            title={content?.title ? content?.title : 'PRD Preview'}
            PaperProps={{
                sx: {
                    width: '650px',
                    height: '100%'
                }
            }}
        >
            <CommonButton
                buttonType='shadow'
                data-copy='false'
                buttonSize='small'
                className={classes('copyIcon', 'mb-2')}
                onClick={handleCopyPRD}
            >
                {isCopied ? 'Copied!' : 'Copy PRD'}
                <ContentCopyIcon />
            </CommonButton>
            <Flexbox fullWidth vertical className={classes('gap-4')}>
                {loading ? <Flexbox align justify className={classes('pt-4')} data-copy='false'><Loader /></Flexbox> : <>
                    {content && Object.entries(returnMainContent(content)).map(([key, value]) => (
                        <Flexbox key={key} vertical className={classes('gap-2')}>
                            <Flexbox align justifyBetween className={classes('pl-5')}>
                                <Flexbox className={classes('prdFieldLabel')}>{key}</Flexbox>
                                <Flexbox align
                                    className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                                    onClick={() => openAiRefineDialog(key)}
                                    data-copy='false'
                                >
                                    <Stars />
                                    <CustomTyphography>AI Refine</CustomTyphography>
                                </Flexbox>
                            </Flexbox>
                            {
                                value !== null && <PRDPreviewItem value={value} />
                            }
                        </Flexbox>
                    ))}
                </>}
            </Flexbox>
        </Dialog>
    )
}

export default PRDPreviewDialog