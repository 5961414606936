import { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { Flexbox, Snackbar, TextArea, CustomTyphography, ConfirmationDialog, ActionsMenu, Loader, IconButton, Tooltip, CommonButton, Dialog } from 'components';
import classNames from 'classnames/bind';
import styles from './assistant.module.scss';
import { ChatIcon, CloseIcon, DeleteIcon, EditIcon, Logo, MenuOpenIcon, PlusCircle, SendIcon } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserSession, getKBItemFromAMA, getUserLatestSession, getUserSession, getUserSessions, renameUserSession, sendFeedback, sendMessage } from './assistant.api';
import { AIStreamingEndCharCode } from 'utils/constants';
import { userSelector } from 'store/user';
import { v4 as uuid } from 'uuid';
import { currentSessionLoadingSelector, deleteSession, messagesSelector, renameChat, sessionIdSelector, sessionsSelector, setMessages, setSessionId } from 'store/assistant-slice';
import { setToLocalStorage } from 'utils/localStorage';
import CommonInput from 'components/CommonInput';
import { AMAResponseTypes, AMAUserFeedback, KBReference, Message } from 'utils/types';
import { capitalize } from 'utils/string';
import PRDPreviewDialog from './components/PRDPreviewDialog';
import FeedbackDialog from './components/FeedbackDialog';
import AMAMessage from './components/AMAMessage';
import AIRefineDialog from './components/AIRefineDialog';
import Markdown from 'components/Markdown';

const classes = classNames.bind(styles);

export default () => {
    const dispatch = useDispatch();

    const [question, setQuestion] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [assistantStatus, setAssistantStatus] = useState<null | string>(null);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [openHistorySidebar, setOpenHistorySidebar] = useState(false);
    const [currentSessionId, setCurrentSessionId] = useState<null | string>(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [chatTitle, setChatTitle] = useState('');
    const [prdPreviewDialog, setPrdPreviewDialog] = useState<{ open: boolean, content?: { [key: string]: string } }>({ open: false })
    const [feedbackStatus, setFeedbackStatus] = useState<'initial' | 'pending' | 'success' | null>(null)
    const [feedback, setFeedback] = useState<AMAUserFeedback | null>(null)
    const [latestMessageId, setLatestMessageId] = useState<number | null>(null)
    const [error, setError] = useState(false);
    const [streamedText, setStreamedText] = useState('');
    const [prdPreviewLoading, setPrdPreviewLoading] = useState(false);
    const [aiRefineDialog, setAiRefineDialog] = useState<{ open: boolean, field?: string }>({ open: false })
    const [refinedContent, setRefinedContent] = useState<string>('')

    const user = useSelector(userSelector);
    const sessionId = useSelector(sessionIdSelector);
    const messages = useSelector(messagesSelector);
    const sessions = useSelector(sessionsSelector);
    const currentSessionLoading = useSelector(currentSessionLoadingSelector);

    useEffect(() => {
        if (!sessionId) {
            const newSessionId = uuid();
            dispatch(setSessionId(newSessionId))
        }
    }, [sessionId]);

    useEffect(() => {
        dispatch(getUserLatestSession(user.id));
        dispatch(getUserSessions(user.id))
    }, [])

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const chatContainerRef = useRef<HTMLDivElement>(null);

    const onSendMessage = async (responseType?: 'text' | 'prd_preview' | 'refine', prompt?: string) => {
        if (sessionId) {
            setFeedbackStatus('initial');
            let generatedText = '';
            let lastMessageType: 'text' | 'prd_preview' | 'refine' = responseType || 'text';
            let jsonResponse: any = {}
            let userPrompt: string = responseType === 'refine' ? `Make the following changes to the field "${capitalize(aiRefineDialog.field || '')}":\n${capitalize(refinedContent)}` : prompt ? prompt : question
            let references: KBReference[] = []

            const newMessages: Message[] = [
                {
                    from: 'user',
                    text: userPrompt,
                    references: []
                },
                ...messages
            ];
            dispatch(setMessages(newMessages));
            setQuestion('');
            setRefinedContent('')
            setIsLoading(true)
            setAiRefineDialog(prev => ({ ...prev, open: false }))
            setPrdPreviewDialog({ open: false })

            dispatch(
                sendMessage(
                    userPrompt,
                    sessionId,
                    (value: any, type?: AMAResponseTypes) => {
                        if (type === AMAResponseTypes.LOG) {
                            setAssistantStatus(value as string)
                        } else if (type === AMAResponseTypes.METADATA) {
                            setLatestMessageId(value.message_id)
                            references = value.references
                        } else {
                            setAssistantStatus(null);
                            let text = '';

                            if (value.type === 'text' || value.type === 'refine') {
                                text = value.content;

                                if (text.charCodeAt(0) === AIStreamingEndCharCode) {
                                    value.type = lastMessageType;
                                }
                            } else if (value.type === 'prd_preview') {
                                jsonResponse = value.content
                                text = Object.values(value.content).join('\n')
                            }
                            generatedText = (generatedText + text).replace('```html', '')
                            setStreamedText(generatedText);

                            lastMessageType = value.type;

                            if (text.charCodeAt(0) === AIStreamingEndCharCode) {
                                setIsLoading(false)
                                const updatedMessages: Message[] = [
                                    {
                                        from: 'assistant',
                                        text: {
                                            // This is a temporary solution, we should change this, when AI will save refine type
                                            type: lastMessageType === 'prd_preview' ? lastMessageType : responseType || lastMessageType,
                                            content: lastMessageType === 'prd_preview' ? jsonResponse : generatedText.trim().replace('```', '')
                                        },
                                        references
                                    },
                                    ...newMessages
                                ];
                                dispatch(setMessages(updatedMessages));
                                setStreamedText('');
                                return;
                            }
                        }
                    },
                    () => {
                        setStreamedText('');
                        setIsLoading(false)
                        setError(true)
                    }
                )
            );
        }
    };

    useEffect(() => {
        if (sessionId) {
            setToLocalStorage(sessionId, sessionId);
        }
    }, [sessionId])

    const handleDeleteChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(deleteUserSession({ userId: user.id, sessionId: currentSessionId }))
                dispatch(deleteSession(currentSessionId));

                if (sessionId === currentSessionId) {
                    handleStartNewSession()
                }
            } catch (error) {
                console.log(error);
            }
        }
        setOpenDeleteConfirmation(false)
        setCurrentSessionId(null)
    }

    const showDeleteConfirmation = (sessionId: string) => {
        setCurrentSessionId(sessionId)
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const handleSelectSession = (sessionId: string) => {
        try {
            dispatch(getUserSession({ userId: user.id, sessionId: sessionId }))
        } catch (error) {
            console.log(error);
        }
    }

    const handleStartNewSession = () => {
        const newSessionId = uuid();
        dispatch(setSessionId(newSessionId))
        dispatch(setMessages([]))
    }

    const resetRenameDialog = () => {
        setOpenRenameDialog(false)
        setCurrentSessionId(null);
        setChatTitle('')
    }

    const handleRenameChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(renameUserSession({ userId: user.id, sessionId: currentSessionId, data: { title: chatTitle } }));
                dispatch(renameChat({ id: currentSessionId, title: chatTitle }))
            } catch (err) {
                console.log(err);
            }
        }

        resetRenameDialog()
    }

    const onResetFeedback = () => {
        setFeedbackStatus(null);
        setFeedback(null)
    }

    const handleSendFeedback = (feedbackType: 'positive' | 'negative') => {
        setFeedback({ type: feedbackType, content: '' })
        setFeedbackStatus('pending')
    }

    const handleSubmitFeedback = async () => {
        if (latestMessageId && sessionId && feedback) {
            try {
                await dispatch(sendFeedback({ userId: user.id, sessionId, messageId: latestMessageId, body: feedback }))
                setFeedbackStatus('success');
            } catch (err) {
                console.log(err);
            }

            setTimeout(() => {
                onResetFeedback()
            }, 1500);
        }
    }

    useEffect(() => {
        if (sessions.every(session => session.session_id !== sessionId) && messages.length >= 2) {
            dispatch(getUserSessions(user.id))
        }
    }, [messages.length, sessionId, sessions.length])

    useEffect(() => {
        if (chatContainerRef.current && streamedText) {
            chatContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [streamedText, messages]);

    const onGetKBSource = async (kbId: number, kbItemIds: number[]) => {
        try {
            await dispatch(getKBItemFromAMA({ kbId, data: { items: kbItemIds } }))
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Flexbox fullWidth className={classes('assistantContainer')}>
            {
                openHistorySidebar && (
                    <Flexbox vertical className={classes('history', 'gap-3')}>
                        <CommonButton
                            buttonType='shadow'
                            variant='text'
                            startIcon={<PlusCircle />}
                            onClick={handleStartNewSession}
                        >
                            New Session
                        </CommonButton>
                        <Flexbox vertical className={classes('gap-3', 'sessions')}>
                            {
                                sessions.map(session => {
                                    return (
                                        <Flexbox
                                            justifyBetween
                                            key={session.session_id}
                                            className={classes('history-item', { active: session.session_id === sessionId })}
                                            onClick={() => handleSelectSession(session.session_id)}
                                        >
                                            <CustomTyphography className={classes('ellipsisText')}>{session.title}</CustomTyphography>
                                            <ActionsMenu className={classes('sessionActions-btn')}
                                                buttonItems={[
                                                    {
                                                        label: 'Rename chat',
                                                        action: () => {
                                                            setOpenRenameDialog(true);
                                                            setCurrentSessionId(session.session_id)
                                                            setChatTitle(session.title)
                                                        },
                                                        icon: <EditIcon />
                                                    },
                                                    { label: 'Delete chat', action: () => showDeleteConfirmation(session.session_id), icon: <DeleteIcon />, type: 'red' },
                                                ]}
                                            />
                                        </Flexbox>
                                    )
                                })
                            }
                        </Flexbox>
                    </Flexbox>
                )
            }
            <Flexbox className={classes('messengerContainer')} fullWidth vertical ref={chatContainerRef}>
                <Flexbox className={classes('messagesContainer')} fullWidth>
                    {currentSessionLoading ? <Flexbox justify fullWidth className={classes('p-4')}><Loader /></Flexbox> : <>
                        {
                            (!!assistantStatus || isLoading) && (
                                <Flexbox className={classes('messageContainer', { fromAI: streamedText.length })}>
                                    <Flexbox className={classes('logoContainer')}>
                                        <Logo />
                                    </Flexbox>
                                    <Flexbox>
                                        {streamedText.length ? (
                                            <Flexbox
                                                fullWidth
                                                vertical
                                            >
                                                <Markdown text={streamedText?.replaceAll('```html', '')?.replaceAll('```', '')} />
                                            </Flexbox>
                                        ) : (
                                            <Flexbox className={classes('gap-1')}>
                                                {assistantStatus &&
                                                    <Flexbox align className={classes('assistantStatus')}>
                                                        <CustomTyphography className={classes('assistantStatus-message')}>{assistantStatus}</CustomTyphography>
                                                    </Flexbox>
                                                }
                                                <Flexbox align className={classes('dotFlashingContainer')}>
                                                    <Flexbox className={classes('dotFlashing')} />
                                                </Flexbox>
                                            </Flexbox>
                                        )}
                                    </Flexbox>
                                </Flexbox>
                            )
                        }

                        {messages.map((message: Message, index) => (
                            <AMAMessage
                                key={index}
                                message={message}
                                showFeedbackConversation={message.from === 'assistant' && index === 0 && !!feedbackStatus}
                                feedbackStatus={feedbackStatus}
                                handlePRDPreview={() => {
                                    setPrdPreviewDialog({ open: true, content: (message.from === 'assistant' && 'content' in message.text ? message.text.content : undefined) as { [key: string]: string } });
                                    setPrdPreviewLoading(true)
                                    setTimeout(() => {
                                        setPrdPreviewLoading(false)
                                    }, 1000);
                                }}
                                handleRefine={() => {
                                    setAiRefineDialog(prev => ({ ...prev, open: true }))
                                }}
                                handleConfirmRefinedPRD={() => {
                                    onSendMessage(undefined, 'Show the full updated PRD')
                                }}
                                handleSendFeedback={handleSendFeedback}
                                closeFeedbackConversation={() => setFeedbackStatus(null)}
                                prdTitle={(message.from === 'assistant' && message.text.type === 'prd_preview') ? message.text.content?.title : undefined}
                                onGetKBSource={onGetKBSource}
                            />
                        )
                        )}
                    </>
                    }
                </Flexbox>

                <Flexbox className={classes('newMessageContainer')} fullWidth>
                    <Flexbox align className={classes('gap-1')}>
                        <Tooltip title={`${openHistorySidebar ? 'Close' : 'Open'} Chat History`}>
                            <IconButton
                                className={classes('action-btn')}
                                disabled={!sessions.length}
                                onClick={() => setOpenHistorySidebar(prev => !prev)}
                            >
                                {openHistorySidebar ? <CloseIcon /> : <MenuOpenIcon />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'New Chat'}>
                            <IconButton
                                className={classes('action-btn')}
                                onClick={handleStartNewSession}
                            >
                                <ChatIcon />
                            </IconButton>
                        </Tooltip>
                    </Flexbox>

                    <TextArea
                        value={question}
                        onKeyDown={onKeyPress}
                        onChange={e => {
                            setQuestion(e.target.value);
                        }}
                        placeholder="Ask here"
                        className={classes('textArea')}
                        fullWidth
                        endAdornment={
                            <SendIcon className={classes('sendIcon')} onClick={() => onSendMessage()} />
                        }
                        autoFocus
                        disabled={isLoading}
                    />
                </Flexbox>
                <ConfirmationDialog
                    open={openDeleteConfirmation}
                    onClose={onCancelDelete}
                    onConfirm={handleDeleteChat}
                    confirmButtonStyle='danger'
                    title='Delete the history?'
                >
                    <Flexbox>
                        Are you sure you want to clear the chat history?
                    </Flexbox>
                </ConfirmationDialog>

                <Snackbar open={error} onClose={() => setError(false)} type="error">
                    <Flexbox>Sorry, An error occurred. Please try again later!</Flexbox>
                </Snackbar>
                <Snackbar open={error} onClose={() => setError(false)} type="error">
                    <Flexbox>Chat deleted successfully!</Flexbox>
                </Snackbar>
            </Flexbox>

            <Dialog
                onClose={resetRenameDialog}
                open={openRenameDialog}
                cancelButton
                title="Rename Chat"
                confirmButton
                onConfirm={handleRenameChat}
                disabled={!chatTitle}
                PaperProps={{
                    sx: {
                        width: '450px'
                    }
                }}
            >
                <Flexbox fullWidth>
                    <CommonInput value={chatTitle} onChange={(e) => setChatTitle(e.target.value)} />
                </Flexbox>
            </Dialog>

            <PRDPreviewDialog
                loading={prdPreviewLoading}
                content={prdPreviewDialog.content}
                open={prdPreviewDialog.open}
                onClose={() => setPrdPreviewDialog({ open: false })}
                setAiRefineDialog={setAiRefineDialog}
            />

            {
                feedback && <FeedbackDialog
                    pendingStatus={feedbackStatus === 'pending'}
                    handleSubmitFeedback={handleSubmitFeedback}
                    feedback={feedback}
                    handleChangeFeedback={e => setFeedback({ type: feedback.type, content: e.target.value })}
                />
            }
            {
                aiRefineDialog.open && <AIRefineDialog
                    open={aiRefineDialog.open}
                    onClose={() => setAiRefineDialog(prev => ({ ...prev, open: false }))}
                    onConfirm={() => onSendMessage('refine')}
                    title={`Refining the ${capitalize(aiRefineDialog?.field || '')}`}
                    refinedContent={refinedContent}
                    setRefinedContent={setRefinedContent}
                />
            }
        </Flexbox>
    );
};
